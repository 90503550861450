<template>
  <div v-if="isLoaded">
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <!-- <SmsTemplateAdd
      ref="smsTemplateAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-sms-template="addSmsTemplate($event)" /> -->
    <SmsTemplateDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-sms-template="initialSmsTemplate"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @edit-sms-template="editSmsTemplate($event)" />
    <SmsTemplateFillter
      :search.sync="filter.searchTemplateName"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add SMS Template"
        to-path="/sms-template/add"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(templateName)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(remark)="data">
          <div v-if="data && data.item && data.item.remark">
            {{ data.item.remark }}
          </div>
          <div v-else>
            -
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import SmsTemplateFillter from '@/components/New/SmsTemplate/SmsTemplateFillter.vue'
// import SmsTemplateAdd from '@/components/New/SmsTemplate/SmsTemplateAdd.vue'
import SmsTemplateDetail from '@/components/New/SmsTemplate/SmsTemplateDetail.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableTop from '@/components/TableTop.vue'
import SmsTemplateProvider from '@/resources/SmsTemplateProvider'

const SmsTemplateService = new SmsTemplateProvider()

export default {
  components: {
    SmsTemplateFillter,
    // SmsTemplateAdd,
    SmsTemplateDetail,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isLoaded: false,
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchTemplateName: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 0
      },
      fields: [
        { key: 'templateName', label: 'Template Name', sortable: true },
        { key: 'message', label: 'Message' },
        // { key: 'remark', label: 'Remark' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialSmsTemplate: {},
      selectedItem: {},
      deleteItem: {}
    }
  },
  async created () {
    try {
      await this.getSmsTemplate()
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    fetchFilter () {
      this.setPagination(1)
      this.getSmsTemplate()
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    async editSmsTemplate (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await SmsTemplateService.update(val.id, payload)
        await this.getSmsTemplate()
        this.setInitialSmsTemplateAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 SMS Template has been updated'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 SMS Template has been Failed'
          }
        })
      }
    },
    rowClick (val) {
      this.$router.push({ name: 'sms-template-detail', params: { id: val.id } })
    },
    setInitialSmsTemplateAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialSmsTemplate = { ...val }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await SmsTemplateService.softDelete(val.ID)
        this.getSmsTemplate()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 SMS Template has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 SMS Template has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    async getSmsTemplate () {
      try {
        const page = this.$route?.query?.page || this.pagination?.page || 1
        const limit = this.$route?.query?.limit || this.pagination?.limit || 10
        const data = await SmsTemplateService.paginate(
          page, limit,
          this.filter
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.log(err)
      }
    },
    // async addSmsTemplate (val) {
    //   try {
    //     await SmsTemplateService.create(val)
    //     await this.getSmsTemplate()
    //     this.resetFormAndToggleSideBar(this.$refs.smsTemplateAdd)
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Add Record Successfully',
    //         icon: 'EditIcon',
    //         variant: 'success',
    //         text: '👋 SMS Template has been added'
    //       }
    //     })
    //   } catch (err) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Add Fail',
    //         icon: 'EditIcon',
    //         variant: 'danger',
    //         text: '😞 SMS Template has been Failed'
    //       }
    //     })
    //   }
    // },
    sortFilter (ctx) {
      console.log(ctx)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getSmsTemplate()
    },
    setPagination (page, totalDocs) {
      this.$router.replace(`sms-template?page=${page}`)
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
